import { ARC_GROUP } from '../../types';

export interface NavDataLink {
  title: string;
  // to - for internal routing
  to: string;
  // User must be authenticated
  authenticated?: boolean;
  // User must have one of the permissions
  permissions?: ARC_GROUP[];
}

export const navData: NavDataLink[] = [
  {
    title: 'Home',
    to: '/home',
  },
  {
    title: 'Courses',
    to: '/courses',
  },
  {
    title: 'Resources',
    to: '/resources',
  },
  {
    title: 'Video Vault',
    to: '/videos',
  },
  {
    title: 'My Learning',
    to: '/account',
    authenticated: true,
  },
  {
    title: 'Admin',
    to: '/admin/info',
    authenticated: true,
    permissions: [ARC_GROUP.ARC_Marketing],
  },
];
