import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/auth-context';
import { captureError } from '../utils/capture-error';
import { checkForRedirectPath, storeRedirectPath } from '../utils/redirect';
import { MaintenanceMessage } from './maintenance-message';

export function RequireAuth(props: {
  children: JSX.Element;
  isAdmin: boolean;
  maintData:
    | {
        maintenanceActive: boolean;
        maintenanceBegin: string;
        maintenanceEnd: string;
      }
    | null
    | undefined;
}) {
  // Context
  const { state, dispatch } = React.useContext(AuthContext);
  // Hooks
  const navigate = useNavigate();

  React.useEffect(() => {
    const handleCheckForPath = async () => {
      try {
        const redirectPath = await checkForRedirectPath();
        console.log({ redirectPath });
        if (redirectPath) {
          navigate(redirectPath);
        }
      } catch (error) {
        captureError({ data: { error } });
      }
    };

    const handleStorePath = async () => {
      try {
        if (window?.location?.pathname) {
          const { pathname } = window.location;
          console.log({ pathname });
          // If the user was trying to access a route other than the main route
          if (pathname !== '/') {
            let path = pathname;
            // If there are any search parameters add them to the redirect path
            if (window?.location?.search) {
              path = path + window.location.search;
            }
            // Add the route to storage for use after logging in
            await storeRedirectPath({ pathname: path });
          }
        }
      } catch (error) {
        captureError({ data: { error } });
      }
    };

    if (state.user !== undefined) {
      // Check for stored path
      handleCheckForPath();
    } else {
      // Store path
      handleStorePath();
    }
  }, [state.user, navigate, dispatch]);

  const isAuthenticated = state.user !== undefined;

  if (!props.isAdmin && props.maintData && props.maintData.maintenanceActive) {
    // Display a Maintenance Message to agents
    return (
      <MaintenanceMessage maintenanceEnd={props.maintData.maintenanceEnd} />
    );
  } else if (state.isChecking) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  } else if (isAuthenticated) {
    return props.children;
  } else {
    return <Navigate to="/sign-in" replace />;
  }
}
